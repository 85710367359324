"use strict";

const contactName = document.getElementById("contact-name");
const contactEmail = document.getElementById("contact-email");
const contactMessage = document.getElementById("contact-message");
const contactMessageValidationError = document.getElementById(
  "contact-message-validation-error"
);

document
  .getElementById("send-enquiry-button")
  .addEventListener("click", function (e) {
    if (contactMessage.value) {
      const body =
        encodeURIComponent(contactMessage.value) +
        "%0A%0A" +
        encodeURIComponent(contactName.value);

      window.location.href =
        "mailto:rob@wearebookable.com?subject=Website%20Enquiry&body=" + body;
      contactName.value = "";
      contactEmail.value = "";
      contactMessage.value = "";
      contactMessageValidationError.classList.add("hidden");
    } else {
      contactMessageValidationError.classList.remove("hidden");
    }
  });
